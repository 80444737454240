import React, {FC} from "react";
import {
    BooleanProperty, DecimalProperty,
    DoubleProperty,
    IntProperty,
    DataObjectBase,
    DataObjectType,
    StringProperty,
    TimestampProperty
} from "../../../../model/data";
import Alert from "react-bootstrap/Alert";
import {IntPostablePropertyComponent} from "./int-postable-property-component";
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {StringPostablePropertyComponent} from "./string-postable-property-component";
import {DoublePostablePropertyComponent} from "./double-postable-property-component";
import {BooleanPostablePropertyComponent} from "./boolean-postable-property-component";
import {TimestampPostablePropertyComponent} from "./timestamp-postable-property-component";
import {DecimalPostablePropertyComponent} from "./decimal-postable-property-component";


export const PostableObjectComponentFactory : FC<PostableComponentPropTypes<DataObjectBase>> = ({dataObject, ...props}) => {

    switch (dataObject.type) {
        case DataObjectType.boolean:
            return <BooleanPostablePropertyComponent {...props} dataObject={dataObject as BooleanProperty}/>
        case DataObjectType.int:
            return <IntPostablePropertyComponent {...props} dataObject={dataObject as IntProperty}/>
        case DataObjectType.double:
            return <DoublePostablePropertyComponent {...props} dataObject={dataObject as DoubleProperty}/>
        case DataObjectType.decimal:
            return <DecimalPostablePropertyComponent {...props} dataObject={dataObject as DecimalProperty} decimalPlaces={2}/>
        case DataObjectType.string:
            return <StringPostablePropertyComponent {...props} dataObject={dataObject as StringProperty}/>
        case DataObjectType.timestamp:
            return <TimestampPostablePropertyComponent {...props} dataObject={dataObject as TimestampProperty}/>
        default:
            return <Alert variant="warning">
                <strong>Warning:</strong> {`Unknown Postable Property Type ${dataObject.type}`}
            </Alert>
    }
}
