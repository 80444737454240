import * as React from 'react';
import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {PlayersRouteItems} from "../../routes/PlayersRoutes";
import {default as ProjectIndex} from "../../routes/project";
import { NotificationErrorBoundary } from '@yakoffice/notification-handler';
import { YakofficeHeader } from '@yakoffice/yakoffice-header';
import {matchDeeply, NotFound} from '@yakoffice/shared-components';

export const App : FC = () => {

  return <div id="app">
        <NotificationErrorBoundary errorTitle="Error Loading Yakoffice Header">
          <YakofficeHeader hostedInPortal={false}>
            <Routes>
              <Route path={matchDeeply(PlayersRouteItems.Projects,PlayersRouteItems.ProjectIdParam)} element={<ProjectIndex />} />
              <Route path={"*"} element={<NotFound />}/>
            </Routes>
          </YakofficeHeader>
        </NotificationErrorBoundary>
      </div>
}

