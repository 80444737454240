import {DataObjectBase, DataProperty} from "../../../../model/data";
import React, { ReactElement, useCallback, useEffect } from 'react';
import {Col, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {EnumInput} from "../Inputs/enum-input";
import {hasToString} from '../has-to-string';

export interface PropTypes<T>{
    postableValue: T
    setPostableValue: (property: DataObjectBase, value: T) => void
    handleAddValidator : (property: DataObjectBase, validator: () => boolean) => void
    parseValue: (value: string) => T
    isValid: (value: T) => boolean
    dataObject: DataProperty<T>
    formComponent: (value: T, handleOnChange: (value: string) => void) => ReactElement;
    labelDecorator?: (label: string) => string
}

export const PostablePropertyComponent = <T extends undefined | hasToString,>({postableValue, setPostableValue, handleAddValidator, parseValue, isValid, dataObject, formComponent, labelDecorator = (label: string) => label} : PropTypes<T>) => {

  const handleOnChange = useCallback((value: string) => {
    const parsedValue = parseValue(value);
    setPostableValue(dataObject, parsedValue)
  }, [dataObject, parseValue, setPostableValue]);

  useEffect(() => {
    handleAddValidator(dataObject, () => isValid(postableValue))
  }, [handleAddValidator, dataObject, isValid, postableValue])

  return <Row className="mb-2">
    <Col>
      <Form.Label data-testid={`label${dataObject.id}`}>{labelDecorator(dataObject.label)}</Form.Label>
    </Col>
    <Col>
      {dataObject.enumValues
        ? <EnumInput value={postableValue != null ? postableValue.toString() : undefined}
                     enumValues={dataObject.enumValues}
                     handleOnChange={handleOnChange}
                     data-testid={`input${dataObject.id}`}/>
        : formComponent(postableValue, handleOnChange)
      }
    </Col>
    <Col>
    </Col>
  </Row>
}
