import Axios from "axios";
import {DataObjectBase} from "../../model/data";
import { bearerTokenProvider, buildHeaderOptions } from '@yakoffice/yakoffice-firebase';
import {FormattedErrorMessage} from "../formatted-error-message";
import React from "react";
import {useShowModal} from "@yakoffice/custom-modal";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {useDataSourceApiUrl} from "../use-data-source-api-url";


export interface IPropertyUpdate {
  route: DataObjectBase[],
  value: any,
  formattedValue: any
}

export const useSavePropertyController = () => {

  const showModal = useShowModal();
  const url = useDataSourceApiUrl()

  const showModalSpinner = (title: string, body: string) => {
    showModal({
      show: true,
      title: title,
      body: <CustomSpinner spinnerText={body} position={'relative'}/>,
      canClose: false
    })
  };

  const updateProperty = async (url: string, route: DataObjectBase[], value: any) => {
    const bearerToken = await bearerTokenProvider.getToken();
    await Axios.put(`${url}${route.map(o => o.id).join('/')}`, {value: value}, buildHeaderOptions(bearerToken))
  }

  const handleSaveProperty = async (propertyUpdate: IPropertyUpdate) => {

    try {
      showModalSpinner("Saving Value", "Saving Value....");
      await updateProperty(url, propertyUpdate.route, propertyUpdate.value);
      showModal({
        show: true,
        title: "Value Saved Successfully",
        body: `${propertyUpdate.route.map(r => r.label).join(" / ")} changed to: ${propertyUpdate.formattedValue}`,
        canClose: true
      })
    } catch (e) {
      showModal({
        show: true,
        title: "Error Saving Value",
        body: <FormattedErrorMessage error={e}/>,
        canClose: true
      })
    }
  }

  const handleSaveProperties = async (propertyUpdates: IPropertyUpdate[]) => {

    showModalSpinner("Saving Values", "Saving Values....");

    const successes : IPropertyUpdate[] = [];
    const failures : {error: any, propertyUpdate: IPropertyUpdate }[] = [];
    for (const propertyUpdate of propertyUpdates) {
      try {
        await updateProperty(url, propertyUpdate.route, propertyUpdate.value)
        successes.push(propertyUpdate)
      } catch (e) {
        failures.push({error:e, propertyUpdate})
      }
    }
    showModal({
      show: true,
      title: "Finished Saving Values",
      body: <div>
        <ul>
          {successes.map((pu, index) => <li key={index}>{pu.route.map(r => r.label).join(" / ")} changed to: {pu.formattedValue}</li>)}
        </ul>
        {failures.length > 0 &&
          <>
            <div>Failures</div>
            <ul>{failures.map((f, index) =>
              <li key={index}>
                {f.propertyUpdate.route.map(r => r.label).join(" / ")} not updated
                <FormattedErrorMessage error={f.error}/>
              </li>)}
            </ul>
          </>
        }
      </div>,
      canClose: true
    })


  }

  return {
    handleSaveProperty,
    handleSaveProperties
  }
}
