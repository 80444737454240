import {DoubleProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import {NumberInput, parseDoubleString} from "../Inputs/number-input";

export const DoublePropertyComponent : FC<DataComponentPropTypes<DoubleProperty>> = ({route, dataObject, readonly, displayLabel}) => {

    const isValid = useCallback((value: number | undefined) => {
        return value != null;
    }, []);

    return <PropertyComponent<number | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly}
        displayLabel={displayLabel}
        parseValue={parseDoubleString}
        isValid={isValid}
        formComponent={(value, handleOnChange, disabled) => (
            <NumberInput value={value} handleOnChange={handleOnChange} disabled={disabled} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
