
export enum PlayersRouteItems {
  Projects        = `projects`,
  ProjectIdParam         = `:projectId`,
  GameEnvironmentIdParam = `:gameEnvironmentId`,
  DataSourceIdParam      = `:dataSourceId`,
  ItemIdParam            = ':itemId'
}

export enum PlayersRoutes {
  Projects        = `/projects`,
  Project         = `/projects/:projectId`,
  GameEnvironment = `/projects/:projectId/:gameEnvironmentId`,
  DeletionFramework = '/projects/:projectId/:gameEnvironmentId/deletionFramework',
  DataSource      = `/projects/:projectId/:gameEnvironmentId/:dataSourceId`,
  Item            = '/projects/:projectId/:gameEnvironmentId/:dataSourceId/:itemId',
}

export function CreateDataSourceRoute(projectId : any, gameEnvironmentId: any, dataSourceId: any){
  return PlayersRoutes.DataSource
    .replace(PlayersRouteItems.ProjectIdParam, projectId)
    .replace(PlayersRouteItems.GameEnvironmentIdParam, gameEnvironmentId)
    .replace(PlayersRouteItems.DataSourceIdParam, dataSourceId);
}

export function CreateItemRoute(projectId : any, gameEnvironmentId: any, dataSourceId: any, itemId: any){
  return PlayersRoutes.Item
    .replace(PlayersRouteItems.ProjectIdParam, projectId)
    .replace(PlayersRouteItems.GameEnvironmentIdParam, gameEnvironmentId)
    .replace(PlayersRouteItems.DataSourceIdParam, dataSourceId)
    .replace(PlayersRouteItems.ItemIdParam, itemId);
}
