import Button from 'react-bootstrap/Button';
import React from 'react';
import { useHideModal, useShowModal, useShowModalSpinner } from '@yakoffice/custom-modal';
import { useGameEnvironments } from '../use-game-environments/use-game-environments';
import { useRequestHeader } from '@yakoffice/yakoffice-firebase';
import { useGetDataSourceApiUrl} from '../use-data-source-api-url';
import axios from 'axios';
import { CreateItemRoute } from '../../routes/PlayersRoutes';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useDataSources } from '../use-data-sources';

export const useCopyItemController = () => {
  const navigate = useNavigate();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const showModalSpinner = useShowModalSpinner();
  const gameEnvironments = useGameEnvironments();
  const dataSources = useDataSources();
  const header              = useRequestHeader()
  const getApiUrl                 = useGetDataSourceApiUrl()

  const getGeName = (geId: string) => gameEnvironments.find(ge => ge.id === geId)?.name

  const handleCopyItem = (projectId: string, dataSourceId: string, fromGeId: string, fromItemId: string , toGeId: string, toItemId: string) => {
    const dataSource = dataSources.find(ds => ds.id === dataSourceId);
    const fromMessage = `"${fromItemId}" in ${getGeName(fromGeId)}`;
    const toMessage = `"${toItemId}" in ${getGeName(toGeId)}`;
    const handleViewCopiedItem = () => {
      hideModal();
      navigate(CreateItemRoute(projectId, toGeId, dataSourceId, toItemId));
    }

    const copyItem = async () => {
      let getResponse;
      try {
        showModalSpinner(`Copying ${dataSource?.name}`, `Copying from ${fromMessage} to ${toMessage}`);
        getResponse = await axios.get(`${getApiUrl({projectId: projectId, gameEnvironmentId: fromGeId, itemId: fromItemId})}/raw`, header);
      } catch (e:any) {
        showModal({
          show: true,
          title: `Error Copying ${dataSource?.name}`,
          body: `Error finding ${dataSource?.name} ${fromMessage}":  ${e.message}`,
          canClose: true
        })
        return;
      }

      try{
        const itemJson = await getResponse.data;
        await axios.put(`${getApiUrl({projectId: projectId, gameEnvironmentId: toGeId, itemId: toItemId})}/raw`, itemJson, header)
        showModal({
          show: true,
          title: `Copying ${dataSource?.name}`,
          body: `${dataSource?.name} copied successfully!`,
          action: <Button variant="success" onClick={handleViewCopiedItem} data-testid="btnModalViewCopied">View Copied {dataSource?.name}</Button>,
          canClose: true
        })
      } catch (e:any) {
        showModal({
          show: true,
          title: `Error Copying ${dataSource?.name}`,
          body: e.response?.status === 404
            ? `${dataSource?.name} ${toMessage} not found`
            : `Error saving to ${dataSource?.name} ${toMessage}:  ${e.message}`,
          canClose: true
        })
      }
    }

    showModal(
      {
        show: true,
        title: `Copy ${dataSource?.name}`,
        body: <span>
          Are you sure you want to copy the {dataSource?.name}:
          <p/>
          From:
          <ul><li><strong>{fromMessage}</strong></li></ul>
          To:
          <ul><li><strong>{toMessage}</strong></li></ul>
          <Alert variant="warning" className="text-center">This is will overwrite {toMessage} and will not be recoverable</Alert>
        </span>,
        action: <Button variant="success" className="me-2" onClick={copyItem} data-testid="btnModalCopy">Copy</Button>,
        canClose: true
      }
    );
  }

  return {handleCopyItem}
}
