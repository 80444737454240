import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { YoGameEnvironmentRoles } from '../../config/yo-game-environment-roles';
import { GameEnvironmentSelector } from '../game-environment-selector';
import { useAuthUser } from '@yakoffice/yakoffice-firebase';
import styles from '../data-source/dataSource.module.sass';

interface InputProps{
  handleGameEnvironmentChange: (gameEnvironmentId: string) => void
  handleSearchItem : (itemId: string) => void
}

export const SearchItemInputGroup : FC<InputProps> = ({handleGameEnvironmentChange, handleSearchItem}) => {

  const authUser = useAuthUser();
  const { projectId, itemId } = useParams() as {projectId: string, itemId: string };
  const [searchItemId, setSearchItemId] = useState<string>("");

  useEffect(() => {
    setSearchItemId(itemId ?? "");
  }, [itemId, setSearchItemId])

  const handleSearchItemIdChanged = (e: ChangeEvent<HTMLInputElement>) => setSearchItemId(e.target.value)

  return authUser && <InputGroup className={styles.searchInputGroup}>
    <InputGroup.Text>
      <i className="fas fa-search" />
    </InputGroup.Text>
    <Form.Control
      type="text"
      placeholder="Please enter item id to search for..."
      value={searchItemId}
      onChange={handleSearchItemIdChanged}
      data-testid="txtSearch"
    />
    <GameEnvironmentSelector
      gameEnvironmentFilter={geId => authUser.hasGameEnvironmentRoleClaim(projectId, geId, YoGameEnvironmentRoles.ViewPlayers)}
      handleGameEnvironmentChange={handleGameEnvironmentChange}
      data-testid="ddlSearchGameEnvironment"
    />

    <Button type="submit" variant="success" disabled={searchItemId == null || searchItemId.length < 1}
            onClick={() => handleSearchItem(searchItemId)} data-testid="btnSearch" style={{ zIndex: 0 }}>
      <span className="text"> Search</span>
    </Button>
  </InputGroup>
}
