import React, {FC, useState} from 'react';
import {Button, Container, InputGroup, Table, Form} from 'react-bootstrap';
import {playerDeletionResult, useDeletionRequests} from './use-deletion-requests';
import {observer} from 'mobx-react-lite';
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {DeletionStatusMap} from './deletion-status-controller';
import {IDeleteUserController, useDeleteUserController} from './use-delete-user-controller';
import {GameEnvironmentSelector} from '../game-environment-selector';
import {YoGameEnvironmentRoles} from '../../config/yo-game-environment-roles';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import {useParams} from 'react-router-dom';
import {array} from 'mobx-state-tree/dist/types/complex-types/array';


interface PropsType {
  handleGameEnvironmentChange: (gameEnvironmentId: string) => void
}

export const DeletionRequests: FC<PropsType>  = observer(({handleGameEnvironmentChange}) => {

  const playerDeletionRequestsResult = useDeletionRequests();
  const deleteUserController = useDeleteUserController();
  const { projectId } = useParams() as {projectId: string};
  const authUser = useAuthUser();

  const [playerId, setPlayerId] = useState( "");

  const renderDeletionRequests = () => {

    if(playerDeletionRequestsResult.deletionRequests && playerDeletionRequestsResult.deletionRequests.length > 0) {

      if(playerId === "")
        return playerDeletionRequestsResult.deletionRequests.map(deletionResult => PlayerDeletions(deletionResult, deleteUserController))

      else
        return playerDeletionRequestsResult.deletionRequests.filter(deletionRequests => deletionRequests.id.includes(playerId)).map(deletionResult => PlayerDeletions(deletionResult, deleteUserController))
    }
  }

  return (
    <div>
      <Container>
        {playerDeletionRequestsResult.isLoaded ?
         <div>
           <InputGroup className="mb-3">
             <InputGroup.Text id="basic-addon1">Player Id</InputGroup.Text>
             <Form.Control
               placeholder="Enter player id"
               onChange={(e) => setPlayerId(e.target.value)}
             />
             {authUser &&
             <GameEnvironmentSelector
               gameEnvironmentFilter={geId => authUser.hasGameEnvironmentRoleClaim(projectId, geId, YoGameEnvironmentRoles.ViewPlayers)}
               handleGameEnvironmentChange={handleGameEnvironmentChange}
               data-testid="ddlSearchGameEnvironment"
             />
             }
           </InputGroup>
           <Table responsive>
             <thead>
             <tr>
               <th>Player Id</th>
               <th>Deletion Scope</th>
               <th>Deletion Status</th>
               <th>Action</th>
             </tr>
             </thead>
             <tbody>
             {
               renderDeletionRequests()
             }
             </tbody>
           </Table>
         </div>
                                               :
         <CustomSpinner spinnerText='Loading deletion requests...'/>
        }
      </Container>
    </div>
  )
});


const PlayerDeletions = (playerDeletionResult: playerDeletionResult, deleteUserController: IDeleteUserController) => {


  const DeletionClassMap: { [key: number]: string } =
    {
      2: "text-danger",
      4: "text-danger",
      6: "text-danger",
      7: "text-danger",
      5: "text-info",
      8: "text-success"
    }

  const handleDelete = (playerId: string) => {
    deleteUserController.handleEndUserDeletion(playerId);
  }

  const handleResumeDeletion = (playerId: string) => {
    deleteUserController.handleResumeUserDeletion(playerId);
  }

  const deletionButton = (playerDeletionResult: playerDeletionResult) => {

    switch(playerDeletionResult.status) {

      case 2:
      case 4:
      case 6:
      case 7:
        return <Button className="ml-2" variant={'warning'} size="sm" onClick={() => handleResumeDeletion(playerDeletionResult.id)}>Resume Deletion</Button>;

      case 5:
        return <Button className="ml-2" variant={'danger'} size="sm" onClick={() => handleDelete(playerDeletionResult.id)}>Confirm Deletion</Button>;

      default:
        return <div></div>
    }
  }

  return (
    <tr key={playerDeletionResult.id}>
      <td>{playerDeletionResult.id}</td>
      <td>{playerDeletionResult.deletionScope}</td>
      <td className={DeletionClassMap[playerDeletionResult.status]}>{DeletionStatusMap[playerDeletionResult.status]}</td>
      <td>{deletionButton(playerDeletionResult)}</td>
    </tr>
  )
}
