import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { GameEnvironmentSelector } from '../game-environment-selector';
import { YoGameEnvironmentRoles } from '../../config/yo-game-environment-roles';
import Button from 'react-bootstrap/Button';
import { useGameEnvironments } from '../use-game-environments/use-game-environments';
import { AuthUserContext } from '@yakoffice/yakoffice-firebase';
import { useParams } from 'react-router-dom';
import styles from '../data-source/dataSource.module.sass';

interface InputProps{
  handleCopyItem: (geId: string, itemId: string) => void;
}

export const CopyToItemInputGroup : FC<InputProps> = ({handleCopyItem}) => {

  const authUser = useContext(AuthUserContext);
  const { projectId } = useParams() as { projectId: string };
  const gameEnvironments = useGameEnvironments();
  const [copyGameEnvironmentId, setCopyGameEnvironmentId] = useState<string | undefined>();
  const [copyItemId, setCopyItemId] = useState<string>("");

  useEffect(() => {
    setCopyGameEnvironmentId(gameEnvironments.find(ge => authUser?.hasGameEnvironmentRoleClaim(projectId, ge.id, YoGameEnvironmentRoles.CopyPlayers))?.id)
  }, [projectId, authUser, gameEnvironments])

  const handleCopyItemIdChanged = (e: ChangeEvent<HTMLInputElement>) => setCopyItemId(e.target.value)
  const handleCopyGameEnvironmentIdChange = (geId: string) => setCopyGameEnvironmentId(geId);
  const handleCopyItemBtnClicked = () => (copyGameEnvironmentId && handleCopyItem) && handleCopyItem(copyGameEnvironmentId, copyItemId);

  return (authUser && copyGameEnvironmentId)
    ? <InputGroup className={styles.searchInputGroup}>
      <InputGroup.Text>
        <i className="fas fa-paste" />
      </InputGroup.Text>
      <Form.Control
        type="text"
        placeholder="Please enter id to overwrite..."
        value={copyItemId}
        onChange={handleCopyItemIdChanged}
        data-testid="txtCopy"
      />
      <GameEnvironmentSelector
        gameEnvironmentFilter={geId => authUser.hasGameEnvironmentRoleClaim(projectId, geId, YoGameEnvironmentRoles.CopyPlayers)}
        selectedGameEnvironmentId={copyGameEnvironmentId}
        handleGameEnvironmentChange={handleCopyGameEnvironmentIdChange}
        data-testid="ddlCopyGameEnvironment"/>
      <Button type="submit" variant="success" disabled={copyItemId.length < 1} onClick={handleCopyItemBtnClicked}
              data-testid="btnCopy"
              style={{ zIndex: 0 }}>
        <span className="text"> Copy</span>
      </Button>
    </InputGroup>
    :null
}
