import {IntProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import {IntInput} from "../Inputs/int-input";
import {parseIntString} from "../Inputs/number-input";

export const IntPropertyComponent : FC<DataComponentPropTypes<IntProperty>> = ({route, dataObject, readonly, displayLabel}) => {

    const isValid = useCallback((value: number | undefined) => {
        if(value == null)
            return false;

        if(dataObject.minValue != null && value < dataObject.minValue)
            return false;
        else if(dataObject.maxValue != null && value > dataObject.maxValue)
            return false

        return true;
    }, [dataObject]);

    return <PropertyComponent<number | undefined>
            route={route}
            dataObject={dataObject}
            readonly={readonly}
            displayLabel={displayLabel}
            parseValue={parseIntString}
            isValid={isValid}
            formComponent={(value, handleOnChange, disabled) => (
                <IntInput value={value}
                          handleOnChange={handleOnChange}
                          disabled={disabled}
                          min={dataObject.minValue}
                          max={dataObject.maxValue}
                          data-testid={`input${dataObject.id}`}
                />
            )}
    />
}
