import React, {FC} from "react";

interface PropTypes{
    error: any
}

export const FormattedErrorMessage : FC<PropTypes> = ({error}) => {

    return error?.response?.data?.errors
      ? <>
          <div><strong>Title: </strong>{error?.response?.data?.title}</div>
          <div><strong>Status: </strong>{error?.response?.data?.status}</div>
          <div><strong>Details: </strong>{
              Object
                  .keys(error?.response?.data?.errors)
                  .map(key => error?.response?.data?.errors[key])
                  .toString()
          }</div>
        </>
      :<div><strong>Error Message: </strong>{error.toString()}</div>
}
