import Axios, {AxiosResponse} from 'axios';
import {DataObjectBase} from '../../model/data';
import {NotificationType, usePublishNotification} from '@yakoffice/notification-handler';
import {useDataSourceApiUrl} from '../use-data-source-api-url';
import {useParams} from 'react-router-dom';
import {useRequestHeader} from '@yakoffice/yakoffice-firebase';
import {useDeleteUserModal} from './use-delete-user-modal';


export interface IDeleteUserController {
  handleStartUserDeletion(): Promise<void>;
  handleEndUserDeletion(playerId: string): Promise<void>;
  handleResumeUserDeletion(playerId: string): Promise<void>;
}

export const useDeleteUserController = (): IDeleteUserController => {

  const params = useParams();
  const header = useRequestHeader();
  const publishNotification = usePublishNotification();
  const url = useDataSourceApiUrl();
  const userDeletionModal = useDeleteUserModal();


  const handleStartUserDeletion = async () => {

    const startDeletion: () => Promise<boolean> = async () => {
      return await Axios
        .post<DataObjectBase>(url + `${params.itemId}/startDeletion`, {}, header)
        .then((_: AxiosResponse<DataObjectBase>) => {
          return true;
        })
        .catch(e => {
                 if (e.response?.status !== 404)
                   publishNotification({
                                         title:            'Error starting deletion for player: ' + params.itemId,
                                         notificationType: NotificationType.Error,
                                         message:          e.toString()
                                       });
                 return false;
               }
        );
    }

    const modalMessage = "This will trigger Miniclip's automated user deletion process and permanently delete this user and all of their data. Are you sure you want to delete this user?";

    if(params.itemId)
      userDeletionModal.startUserDeletionModal(startDeletion, modalMessage, true, params.itemId);
  };

  const handleEndUserDeletion = async (playerId: string) => {

    const endDeletion: () => Promise<boolean> = async () => {

      return await Axios
        .post<DataObjectBase>(url + `${playerId}/endDeletion`, {}, header)
        .then((_: AxiosResponse<DataObjectBase>) => {
          return true;
        })
        .catch(e => {
                 if (e.response?.status !== 404)
                   publishNotification({
                                         title:            'Error ending deletion for player: ' + playerId,
                                         notificationType: NotificationType.Error,
                                         message:          e.toString()
                                       });
                 return false;
               }
        );
    }

    const modalMessage = "This will inform Miniclip that the user deletion process has been completed. Before doing this you should ensure that the user data has been deleted from all Yakuto services. This includes all manual deletion steps. Are you sure want to confirm?";

    if(playerId)
      userDeletionModal.endUserDeletionModal(endDeletion, modalMessage, true, playerId);
  };

  const handleResumeUserDeletion = async (playerId: string) => {
    const resumeDeletion: () => Promise<boolean> = async () => {

      return await Axios
        .post<DataObjectBase>(url + `${playerId}/resumeDeletion`, {}, header)
        .then((_: AxiosResponse<DataObjectBase>) => {
          return true;
        })
        .catch(e => {
                 if (e.response?.status !== 404)
                   publishNotification({
                                         title:            'Error resuming deletion for player: ' + playerId,
                                         notificationType: NotificationType.Error,
                                         message:          e.toString()
                                       });
                 return false;
               }
        );
    }

    const modalMessage = "This will attempt to resume the deletion process from where it failed. Are you sure want to continue?";

    if(playerId)
      userDeletionModal.resumeUserDeletionModal(resumeDeletion, modalMessage, true, playerId);
  };

  return {
    handleStartUserDeletion,
    handleEndUserDeletion,
    handleResumeUserDeletion
  };
};
