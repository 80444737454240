import React, {FC}      from 'react';
import {Routes, Route, useParams, Navigate}  from "react-router-dom";
import {CreateDataSourceRoute, PlayersRouteItems} from '../PlayersRoutes';
import { useAuthUser} from '@yakoffice/yakoffice-firebase';
import {GameEnvironmentIndex} from './game-environment';
import { useDataSources } from '../../components';
import {matchDeeply, NoAccess} from '@yakoffice/shared-components';

export const ProjectIndex : FC = () => {

  const { projectId } = useParams() as { projectId: string };
  const authUser = useAuthUser()
  const dataSources = useDataSources();


  return authUser?.isAuthorisedForHostedToolProject(projectId)
    ? <Routes>
        <Route path={""} element={dataSources.length > 0 && <Navigate to={CreateDataSourceRoute(projectId, authUser?.getFirstAuthorisedGameEnvironmentId(projectId), dataSources.find(_ => true)?.id)} />} />
        <Route path={matchDeeply(PlayersRouteItems.GameEnvironmentIdParam)} element={<GameEnvironmentIndex />}/>
      </Routes>
    : <NoAccess />
}

export default ProjectIndex;

