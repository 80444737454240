import React, {FC} from "react";
import {Form} from "react-bootstrap";

interface PropTypes{
    value : string | undefined
    handleOnChange: (value: string) => void
    disabled?: boolean,
    maxLength?: number,
}

export const StringInput : FC<PropTypes> = ({value, handleOnChange, maxLength, ...props}) => {

    return <>
        <Form.Control type="text"
                      {...props}
                      value={value != null ? value : ""}
                      onChange={(e) => handleOnChange(e.target.value)}
                      maxLength={maxLength}/>
        {maxLength &&
        <small className="form-text text-muted">
            {`Max length: ${maxLength}`}
        </small>
        }
    </>
}
