import {useEffect, useState}                      from "react";
import Axios, {AxiosResponse}                     from "axios";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import {useRequestHeader}                         from "@yakoffice/yakoffice-firebase";
import {DataObjectBase}                         from "../../model/data";
import {useDataSourceApiUrl}                          from "../use-data-source-api-url";

export interface useItemSearchParameters {
  itemId: string
}

interface useItemResult{
  isLoaded : boolean;
  isLoading: boolean;
  item    : DataObjectBase | null
}


export const useItem = (searchParameters: useItemSearchParameters) => {

  const publishNotification = usePublishNotification()
  const header              = useRequestHeader()
  const url                 = useDataSourceApiUrl()
  const [result, setResult] = useState<useItemResult>({isLoaded: false, isLoading: false, item: null});

  useEffect(() => {

    if(searchParameters.itemId.length > 0 && header != null) {
      setResult({isLoaded: false, isLoading: true, item: null});

      Axios
        .get<DataObjectBase>( url + searchParameters.itemId, header)
        .then((response: AxiosResponse<DataObjectBase>) => {
          setResult({isLoaded: true, isLoading: false, item: response.data});
        })
        .catch(e => {
                 setResult({isLoaded: true, isLoading: false, item: null});
                 if(e.response?.status !== 404)
                   publishNotification({
                                         title: "Error Loading Item with Id: " + searchParameters.itemId,
                                         notificationType: NotificationType.Error,
                                         message: e.toString()
                                       });
               }
        )
    }
    else
      setResult({isLoaded: false, isLoading: false, item: null});
  }, [publishNotification, header, url, searchParameters]);

  return result;
};
