import {TimestampProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import moment from "moment/moment";
import {DateTimePicker, parseDatetimeString} from '../Inputs/date-time-picker';

export const TimestampPropertyComponent : FC<DataComponentPropTypes<TimestampProperty>> = ({route, dataObject, readonly, displayLabel}) => {

    const isValid = useCallback((value: number | undefined) => {
        return value != null && moment(value).isValid();
    }, []);

    return <PropertyComponent<number | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly}
        displayLabel={displayLabel}
        labelDecorator={(label) => label + " (UTC)"}
        parseValue={parseDatetimeString}
        isValid={isValid}
        formComponent={(value, handleOnChange, disabled) => (
            <DateTimePicker value={value} disabled={disabled} handleOnChange={handleOnChange} data-testid={`input${dataObject.id}`}/>
        )}
        formatValue={value => moment.utc(value).format("DD MMM YYYY HH:mm [UTC]")}
    />
}
