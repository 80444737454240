import {StringProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {PostablePropertyComponent} from "./postable-property-component";
import {StringInput} from "../Inputs/string-input";

export const StringPostablePropertyComponent : FC<PostableComponentPropTypes<StringProperty>> = ({dataObject, ...props}) => {

    const isValid = useCallback((value: string | undefined) => {
        return !((value == null || value.length === 0) && dataObject.isRequired);
    }, [dataObject]);

    return <PostablePropertyComponent<string | undefined>
        {...props}
        parseValue={v => v}
        isValid={isValid}
        dataObject={dataObject}
        formComponent={(value, handleOnChange) => (
            <StringInput value={value} handleOnChange={handleOnChange} maxLength={dataObject.maxLength} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
