import {BooleanProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import {BooleanInput, parseBooleanString} from "../Inputs/boolean-input";

export const BooleanPropertyComponent : FC<DataComponentPropTypes<BooleanProperty>> = ({route, dataObject, readonly, displayLabel}) => {

    const isValid = useCallback((value: boolean | undefined) => {return value != null;}, []);

    return <PropertyComponent<boolean | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly}
        displayLabel={displayLabel}
        parseValue={parseBooleanString}
        isValid={isValid}
        formComponent={(value, handleOnChange, disabled) => (
            <BooleanInput value={value} handleOnChange={handleOnChange} disabled={disabled} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
