export const DeletionStatusMap: {[key: number]: string} =
  {
    0: "Yakuto Deletion Started",
    1: "Yakuto Deletion Completed",
    2: "Yakuto Deletion Failed",
    3: "Miniclip Deletion Request Sent",
    4: "Miniclip Deletion Request Failed",
    5: "Miniclip Deletion Success | Awaiting our confirmation",
    6: "Miniclip Deletion Failed",
    7: "Miniclip Deletion Complete Request Failed",
    8: "Done"
  }
