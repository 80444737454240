import React, {FC}      from 'react';
import {Routes, Route, useParams}  from "react-router-dom";
import { DataSource, useDataSources } from '../../../../components';
import {PlayersRouteItems} from "../../../PlayersRoutes";

export const DataSourceIndex : FC = () => {

  const {dataSourceId} = useParams<{dataSourceId:string}>()
  const dataSources = useDataSources();

  return dataSources.find(ds => ds.id === dataSourceId)
    ?<Routes>
          <Route path={""} element={<DataSource />} />
          <Route path={PlayersRouteItems.ItemIdParam} element={<DataSource />} />
        </Routes>
    : null
}

export default DataSourceIndex;

