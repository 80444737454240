import React, {FC} from "react";
import {Form} from 'react-bootstrap';



interface PropTypes{
  value : string | undefined
  handleOnChange: (value: string) => void
  disabled?: boolean,
  maxLength?: number,
}

export const ImageInput : FC<PropTypes> = ({value, handleOnChange, maxLength, ...props}) => {

  return <>
    {value && <img alt="Avatar Url" src={value} className="mb-2" />}
    <Form.Control type="text"
                  {...props}
                  value={value != null ? value : ""}
                  onChange={(e) => handleOnChange(e.target.value)}
                  maxLength={maxLength}/>
    {maxLength &&
    <small className="form-text text-muted">
      {`Max length: ${maxLength}`}
    </small>
    }
  </>
}
