import React, { FC } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDataSource, useDataSources } from '../use-data-sources';
import { YoDataSource } from '@yakoffice/yakoffice-firebase';

interface PropTypes{
  handleDataSourceChange?: (dataSource: YoDataSource) => void
}

export const DataSourceSelector : FC<PropTypes> = ({handleDataSourceChange}) => {

  const dataSource = useDataSource();
  const dataSources = useDataSources();

  return (
    <DropdownButton
      align="end"
      title={<span className="text-capitalize">{dataSource && dataSource.name}</span>}
      variant="secondary"
      data-testid="ddlDataSource">
      {dataSources.map(ds =>
        <Dropdown.Item key={ds.id} as="button" onClick={() => handleDataSourceChange && handleDataSourceChange(ds)}>
          {ds.name}
        </Dropdown.Item>
      )}
    </DropdownButton>
  )
}
