import {DataObjectBase, PostableObject} from "../../../../model/data";
import React, { FC, useCallback, useState } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {PostableObjectComponentFactory} from "./postable-object-component-factory";
import {useSavePostableController} from "../../../use-save-postable-controller";
import { DataObjectCard } from '../data-object-card';

interface ValidatorDictionary {
    [key: string] :  () => boolean
}

export const PostableComponent : FC<DataComponentPropTypes<PostableObject>> = ({route, dataObject}) => {

    const [postableValue, setPostableValue] = useState<{ [key: string]: any }>({})
    const [validators, setValidators] = useState<ValidatorDictionary>({})
    const controller = useSavePostableController()

    const handleAddValidator = useCallback((property: DataObjectBase, validator: () => boolean) => {
        setValidators(existingValidators => ({...existingValidators, [property.id]: validator}))
    }, [setValidators]);

    const isValid = useCallback(() => {
      return Object.values(validators).every(v => v());
    }, [validators]);

    const handleSetValue = useCallback((property: DataObjectBase, propValue: any) => {
        setPostableValue(existingPostableValue => ({...existingPostableValue, [property.id]: propValue}))
    }, [setPostableValue]);

    const handleSave = async () => {
        await controller.handleSaveProperty([...route, dataObject], dataObject, postableValue)
        setPostableValue({})
    }

    return (
        <DataObjectCard>
            <Card.Body>
                {dataObject.value.map(property => (
                    <PostableObjectComponentFactory key={route.map(o => o.id).join("-") + property.id}
                                                    postableValue={postableValue[property.id]}
                                                    setPostableValue={handleSetValue}
                                                    handleAddValidator={handleAddValidator}
                                                    dataObject={property}/>
                ))}
              <Row>
                  <Col className="d-none d-md-block" />
                  <Col className="d-none d-md-block" />
                  <Col>
                        <Button variant="success" onClick={handleSave} disabled={!isValid()} data-testid={`btnSave${dataObject.id}`}>
                            <i className="fas fa-save icon-with-text"/>
                            <span className="text"> Send</span>
                        </Button>
                    </Col>
              </Row>
            </Card.Body>
        </DataObjectCard>
    )
}
