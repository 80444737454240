import React, {FC} from "react";
import {
  BooleanProperty, DecimalProperty,
  DoubleProperty, ImageProperty,
  IntProperty, ObjectProperty,
  DataObjectBase,
  DataObjectType, PostableObject,
  StringProperty,
  TimestampProperty, UrlLinkProperty
} from '../../../../model/data';
import Alert from "react-bootstrap/Alert";
import {IntPropertyComponent} from "./int-property-component";
import {DataObjectComponent} from "../../data-object-component";
import {DataComponentPropTypes} from "../data-component-prop-types";
import {StringPropertyComponent} from "./string-property-component";
import {DoublePropertyComponent} from "./double-property-component";
import {TimestampPropertyComponent} from "./timestamp-property-component";
import {PostableComponent} from "../postable-properties/postable-component";
import {BooleanPropertyComponent} from "./boolean-property-component";
import {DecimalPropertyComponent} from "./decimal-property-component";
import { ImagePropertyComponent } from './image-property-component';
import { ArrayPropertyComponent } from './array-property-component';
import { UrlLinkPropertyComponent } from './url-link-property-component';

export const DataObjectComponentFactory : FC<DataComponentPropTypes<DataObjectBase>> = ({dataObject, ...props}) => {

  switch (dataObject.type) {
    case DataObjectType.boolean:
      return <BooleanPropertyComponent {...props} dataObject={dataObject as BooleanProperty} />
    case DataObjectType.object:
      return <DataObjectComponent {...props} dataObject={dataObject as ObjectProperty} />
    case DataObjectType.int:
      return <IntPropertyComponent {...props} dataObject={dataObject as IntProperty} />
    case DataObjectType.double:
      return <DoublePropertyComponent {...props} dataObject={dataObject as DoubleProperty} />
    case DataObjectType.decimal:
      return <DecimalPropertyComponent {...props} dataObject={dataObject as DecimalProperty} decimalPlaces={2} />
    case DataObjectType.string:
      return <StringPropertyComponent {...props} dataObject={dataObject as StringProperty} />
    case DataObjectType.image:
      return <ImagePropertyComponent {...props} dataObject={dataObject as ImageProperty} />
    case DataObjectType.timestamp:
      return <TimestampPropertyComponent {...props} dataObject={dataObject as TimestampProperty} />
    case DataObjectType.urlLink:
      return <UrlLinkPropertyComponent {...props} dataObject={dataObject as UrlLinkProperty} />
    case DataObjectType.array:
      return <ArrayPropertyComponent {...props} dataObject={dataObject as ObjectProperty} />
    case DataObjectType.postable:
      return <PostableComponent {...props} dataObject={dataObject as PostableObject} />
    default:
      return <Alert variant="warning">
        <strong>Warning:</strong> {`Unknown Property Type ${dataObject.type}`}
      </Alert>
  }
}
