import { useParams } from 'react-router-dom';
import { useYoProjectsSnapshots } from '@yakoffice/yakoffice-firebase';
import { useEffect, useState } from 'react';

export const useGameEnvironments = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const { yoProjects } = useYoProjectsSnapshots();
  const [gameEnvironments, setGameEnvironments] = useState<{id:string, name:string}[]>([]);

  useEffect(() => {
    const getProjectGameEnvironments = () => yoProjects.find(p => p.id.toLowerCase() === projectId)?.tools.find(t => t.toolId === 'players')?.gameEnvironments || [];
    setGameEnvironments(getProjectGameEnvironments().map(ge => ({ id: ge.toolGeId, name: ge.name })));
  }, [projectId, yoProjects, setGameEnvironments]);

  return gameEnvironments;
}
