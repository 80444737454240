import {DecimalProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {NumberInput, parseDecimalString} from "../Inputs/number-input";
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {PostablePropertyComponent} from "./postable-property-component";

interface PropTypes extends PostableComponentPropTypes<DecimalProperty>{
    decimalPlaces: number;
}

export const DecimalPostablePropertyComponent : FC<PropTypes> = ({dataObject, decimalPlaces,  ...props}) => {

    const isValid = useCallback((value: number | undefined) => {
        return !(value == null && dataObject.isRequired);
    }, [dataObject]);

    return <PostablePropertyComponent<number | undefined>
        {...props}
        parseValue={parseDecimalString(decimalPlaces)}
        isValid={isValid}
        dataObject={dataObject}
        formComponent={(value, handleOnChange) => (
            <NumberInput value={value} handleOnChange={handleOnChange} disabled={!dataObject.isEditable} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
