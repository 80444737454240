import {useDataSourceApiUrl} from '../use-data-source-api-url';
import {useEffect, useState} from 'react';
import Axios from 'axios';
import {DataObjectBase} from '../../model/data';
import {NotificationType, usePublishNotification} from '@yakoffice/notification-handler';
import {useRequestHeader} from '@yakoffice/yakoffice-firebase';


export interface playerDeletionRequestsResult {
  isLoaded: boolean;
  deletionRequests: playerDeletionResult[] | null;
}

export interface playerDeletionResult {
  id: string,
  status: number,
  deletionScope: string
}

export const useDeletionRequests = () => {

  const url     = useDataSourceApiUrl();
  const header  = useRequestHeader();
  const publishNotification = usePublishNotification()


  const [result, setResult] = useState<playerDeletionRequestsResult>(() => ({
    isLoaded            : false,
    deletionRequests    : []}));


  useEffect(() => {
  if(header)
    Axios
      .get<DataObjectBase>(url + `playerDeletionRequests`, header)
      .then((response) => {
        setResult({isLoaded: true, deletionRequests: response.data as any});
      })
      .catch(e => {
               if (e.response?.status !== 404)
                 publishNotification({
                                       title:            'Error retrieving player deletion requests',
                                       notificationType: NotificationType.Error,
                                       message:          e.toString()
                                     });
               setResult({isLoaded: true, deletionRequests: []})
             }
      );

  }, [header, url])
  return result;
}
