import {DecimalProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import {NumberInput, parseDecimalString} from "../Inputs/number-input";

interface PropTypes extends DataComponentPropTypes<DecimalProperty>{
    decimalPlaces: number;
}

export const DecimalPropertyComponent : FC<PropTypes> = ({route, dataObject, decimalPlaces, readonly, displayLabel}) => {

    const isValid = useCallback((value: number | undefined) => {
        return value != null;
    }, []);

    return <PropertyComponent<number | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly}
        displayLabel={displayLabel}
        parseValue={parseDecimalString(decimalPlaces)}
        isValid={isValid}
        formComponent={(value, handleOnChange, disabled) => (
            <NumberInput value={value} handleOnChange={handleOnChange} disabled={disabled} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
