import React, {FC} from "react";
import {Form} from "react-bootstrap";

interface PropTypes{
    value : number | undefined
    handleOnChange: (value: string) => void
    disabled?: boolean
    min?: number,
    max?: number,
}

export const NumberInput : FC<PropTypes> = ({value, handleOnChange, ...props}) => {

  return <Form.Control type="number"
                       {...props}
                       value={value != null ? value : ""}
                       onChange={(e) => handleOnChange(e.target.value)}
                       step={1} />
}

export const parseDoubleString = (value: string) => {
    return value.length > 0 ? Number.parseFloat(value) : undefined
}

export const parseDecimalString = (decimalPlaces: number) => {
    return (value: string) => value.length > 0 ? Number.parseFloat(Number.parseFloat(value).toFixed(decimalPlaces)) : undefined
}

export const parseIntString = (value: string) => {
    return value.length > 0 ? Number.parseInt(value) : undefined
}
