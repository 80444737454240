import { useParams } from 'react-router-dom';
import { player_api_url } from '../../config/player-api-url';

export const useGetDataSourceApiUrl = () => {

  const {projectId, dataSourceId, gameEnvironmentId} = useParams<{projectId: string, dataSourceId: string, gameEnvironmentId: string}>()

  return (overrides: {projectId?: string, gameEnvironmentId?: string, dataSourceId?: string, itemId? :string}) => {
    return `${player_api_url}/projects/${overrides.projectId ?? projectId}/game-environments/${overrides.gameEnvironmentId ?? gameEnvironmentId}/${overrides.dataSourceId ?? dataSourceId}/${overrides.itemId ?? ""}`;
  }
}
