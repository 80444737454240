import {DataObjectBase, DataProperty} from "../../../../model/data";
import React, {ReactElement, useState} from 'react';
import {Col, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {useSavePropertyController} from "../../../use-save-property-controller";
import {EnumInput} from "../Inputs/enum-input";
import {hasToString} from '../has-to-string';
import {useSaveAllControllerContext} from "../../../use-save-all-controller";

export interface PropTypes<T>{
    route: DataObjectBase[]
    dataObject: DataProperty<T>
    readonly: boolean
    parseValue: (value: string) => T
    isValid: (value: T) => boolean
    formComponent: (value: T, handleOnChange: (value: string) => void, disabled: boolean) => ReactElement;
    formatValue?: (value: any) => any;
    displayLabel?: boolean,
    labelDecorator?: (label: string) => string
}

export const PropertyComponent = <T extends undefined | hasToString,>({parseValue, isValid, route, dataObject, readonly, formComponent, formatValue = (value: T | null) => value?.toString(), displayLabel, labelDecorator = (label: string) => label} : PropTypes<T>) => {

  const [value, setValue] = useState<T>(dataObject.value);
  const [valid, setValid] = useState<boolean>(true)
  const [originalValue, setOriginalValue] = useState<T | null>(null)
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const controller = useSavePropertyController()
  const saveAllController = useSaveAllControllerContext();

  const handleOnChange = (newValue: string) => {
    setUnsavedChanges(true);

    if (originalValue == null)
      setOriginalValue(value)

    const parsedValue = parseValue(newValue);
    const propertyIsValid = isValid(parsedValue);

    setValid(propertyIsValid)
    setValue(parsedValue)

    const propertyRoute = [...route, dataObject];
    if (propertyIsValid)
      saveAllController?.addUpdatedProperty(propertyRoute, parsedValue, formatValue(parsedValue));
    else
      saveAllController?.removeUpdatedProperty(propertyRoute)
  };

  const handleSave = async () => {
    await controller.handleSaveProperty({route: [...route, dataObject], value, formattedValue: formatValue(value)})
    setOriginalValue(null);
    setUnsavedChanges(false);
    saveAllController?.removeUpdatedProperty([...route, dataObject])
  }

  const resetToOriginalValue = () => {
    if (originalValue !== null) {
      setValue(originalValue);
      setOriginalValue(null);
      setUnsavedChanges(false);
      saveAllController?.removeUpdatedProperty([...route, dataObject]);
    }
  }

  const disabled = !dataObject.isEditable || readonly;

  return <Row>
    {displayLabel &&
      <Col className={`ps-${route.length * 2}`} sm={12} md={4}>
        <Form.Label data-testid={`label${dataObject.id}`}>{labelDecorator(dataObject.label)}</Form.Label>
      </Col>
    }
    <Col sm={12} md={displayLabel ? 4 : (disabled ? 12 : 6)}>
      <div>
        {dataObject.enumValues
          ? <>
            <EnumInput value={value != null ? value.toString() : undefined}
                       enumValues={dataObject.enumValues}
                       disabled={disabled}
                       handleOnChange={handleOnChange}
                       data-testid={`input${dataObject.id}`}
            />
            {originalValue != null &&
              <small>Original value: <strong>{dataObject.enumValues[originalValue.toString()]}</strong></small>
            }
          </>
          : <>
            {formComponent(value, handleOnChange, disabled)}
            {originalValue != null &&
              <div>
                <small data-testid={`originalValue${dataObject.id}`}>
                  Original value: <strong>{formatValue(originalValue)}</strong>
                </small>
              </div>
            }
          </>
        }
      </div>
    </Col>
    {!disabled &&
      <Col sm={12} md={displayLabel ? 4 : 6}>
        <Button variant="success" onClick={handleSave} disabled={!valid || !unsavedChanges} data-testid={`btnSave${dataObject.id}`}>
          <i className="fas fa-save icon-with-text"/>
          <span className="text"> Save</span>
        </Button>
        {originalValue != null &&
          <Button variant="secondary" className="ms-2" onClick={resetToOriginalValue} data-testid={`btnReset${dataObject.id}`}>
            <i className="fas fa-undo-alt"/>
          </Button>
        }
      </Col>
    }
  </Row>
}
