import {IntProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {PostablePropertyComponent} from "./postable-property-component";
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {IntInput} from "../Inputs/int-input";
import {parseIntString} from "../Inputs/number-input";

export const IntPostablePropertyComponent : FC<PostableComponentPropTypes<IntProperty>> = ({dataObject, ...props}) => {

    const isValid = useCallback((value: number | undefined) => {
        if(value == null && dataObject.isRequired)
            return false;

        if(dataObject.minValue != null && value != null && value < dataObject.minValue)
            return false;
        else if(dataObject.maxValue != null && value != null && value > dataObject.maxValue)
            return false

        return true;
    }, [dataObject]);

    return <PostablePropertyComponent<number | undefined>
            {...props}
            parseValue={parseIntString}
            isValid={isValid}
            dataObject={dataObject}
            formComponent={(value, handleOnChange) =>
                    <IntInput value={value}
                              handleOnChange={handleOnChange}
                              min={dataObject.minValue}
                              max={dataObject.maxValue}
                              data-testid={`input${dataObject.id}`}
                    />
            }
    />
}
