import React, {FC} from "react";
import {Form} from "react-bootstrap";

interface PropTypes{
    value : string | undefined
    enumValues : {[key: string]: string}
    handleOnChange: (value: string) => void
    disabled?: boolean
}

export const EnumInput : FC<PropTypes> = ({value, enumValues, handleOnChange, disabled, ...props}) => {

    return <Form.Select
                         value={value != null ? value : ""}
                         onChange={e => handleOnChange(e.target.value)}
                         disabled={disabled}
                         {...props}>
        <option value="">Select a value...</option>
        {Object
            .keys(enumValues)
            .map(key =>
                <option key={key} value={key}>{enumValues[key]}</option>
            )}
    </Form.Select>
}
