import { Tabs } from 'react-bootstrap';
import React, { FC } from 'react';
import { TabsProps } from 'react-bootstrap/Tabs';
import { useGameEnvironmentCssClass } from '@yakoffice/use-game-environment-css-class';
import styles from './item-tabs.module.sass'

export const ItemTabs : FC<TabsProps> = ({...props}) => {
  const cssClass = useGameEnvironmentCssClass()
  return <Tabs className={`${styles.navTabs} ${cssClass}`} {...props}>{props.children}</Tabs>
}
