import React from "react";
import Axios from "axios";
import {DataObjectBase} from "../../model/data";
import { bearerTokenProvider, buildHeaderOptions } from '@yakoffice/yakoffice-firebase';
import {useShowModal} from "@yakoffice/custom-modal";
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {FormattedErrorMessage} from "../formatted-error-message";
import {useDataSourceApiUrl} from "../use-data-source-api-url";

export const useSavePostableController = () => {

    const showModal = useShowModal();
    const url = useDataSourceApiUrl()

    const showModalSpinner = (title: string, body: string) => {showModal({show: true,title: title,body: <CustomSpinner spinnerText={body} position={"relative"} />,canClose: false})};

    const handleSavePostable = async (route : DataObjectBase[], dataObject: DataObjectBase, value: any) => {

        try{
            showModalSpinner(`Sending ${dataObject.label}`, `Sending ${dataObject.label}....`);
            const bearerToken = await bearerTokenProvider.getToken();
            await Axios
                .post(`${url}${route.map(o => o.id).join('/')}`, value, buildHeaderOptions(bearerToken))
            showModal({
                show: true,
                title: `Sending ${dataObject.label}`,
                body: `${dataObject.label} sent successfully!`,
                canClose: true
            })
        }catch(e){
            showModal({
                show: true,
                title: `Error Sending ${dataObject.label}`,
                body: <FormattedErrorMessage error={e} />,
                canClose: true
            })
        }
    }

    return {
        handleSaveProperty: handleSavePostable
    }

}
