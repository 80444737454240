import React, { FC } from 'react';
import { useParams} from "react-router-dom";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { useGameEnvironmentCssClass } from '@yakoffice/use-game-environment-css-class';
import { useAuthUser} from '@yakoffice/yakoffice-firebase';
import { useGameEnvironments } from '../use-game-environments/use-game-environments';

interface PropTypes{
    gameEnvironmentFilter?: (geId: string) => boolean
    selectedGameEnvironmentId?: string,
    handleGameEnvironmentChange?: (gameEnvironmentId: string) => void
}

export const GameEnvironmentSelector : FC<PropTypes> = ({gameEnvironmentFilter, selectedGameEnvironmentId, handleGameEnvironmentChange, ...props}) => {

  const gameEnvironments = useGameEnvironments();
  const { gameEnvironmentId } = useParams<{ gameEnvironmentId: string }>()
  // Note: ignore Error Because Header Already Throws If This Subscription Fails
  const cssClass = useGameEnvironmentCssClass(selectedGameEnvironmentId);
  const authUser = useAuthUser();

  const changeGameEnvironment = (newGameEnvironmentId: string) => handleGameEnvironmentChange &&  handleGameEnvironmentChange(newGameEnvironmentId);
  const getCurrentGameEnvironmentName = () => gameEnvironments.find(ge => ge.id ===  (selectedGameEnvironmentId ?? gameEnvironmentId))?.name;

  return (authUser &&
      <Dropdown align="end">
        <Dropdown.Toggle variant={cssClass} style={{zIndex:0}} {...props}>
          <span className="text-capitalize">{getCurrentGameEnvironmentName()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        {gameEnvironments
          .filter(ge => gameEnvironmentFilter ? gameEnvironmentFilter(ge.id) : true)
          .map(ge =>
            <Dropdown.Item key={ge.id} as="button" onClick={() => changeGameEnvironment(ge.id)}>
              {ge.name}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
  );
}
