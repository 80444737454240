import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useYoProjectsSnapshots, YoDataSource, YoProjectPlayersMap } from '@yakoffice/yakoffice-firebase';

export const useDataSources = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const { yoProjects } = useYoProjectsSnapshots();
  const [dataSources, setDataSources] = useState<YoDataSource[]>([]);

  useEffect(() => {
    const projectPlayersMap = yoProjects.find(p => p.id.toLowerCase() === projectId)?.tools.find(t => t.toolId === 'players') as YoProjectPlayersMap;
    projectPlayersMap?.dataSources && setDataSources(projectPlayersMap.dataSources);
  }, [projectId, yoProjects, setDataSources]);

  return dataSources;
}
