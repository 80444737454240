import React, { FC } from 'react';
import {Form}                                   from "react-bootstrap";
import moment                                   from "moment/moment";
import {InputGroup} from 'react-bootstrap/cjs';

export const DateTimeLocalInputFormat = "YYYY-MM-DDTHH:mm"
export const IsoDateFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

/**
 * @return Unix timestamp
 * @param value unix timestamp string or ISO date string
 */
export const parseDatetimeString = (value: string) : (number | undefined) => {
  if(value.length > 0) {
    // Note: This is to handle result from DateTimePicker
    const fromIsoString = moment(value, moment.ISO_8601);
    if (fromIsoString.isValid())
      return fromIsoString.valueOf();

    // Note: This is to handle result from enumValues (i.e. selection)
    const fromUnixTimestamp = moment(parseInt(value));
    if (fromUnixTimestamp.isValid())
      return fromUnixTimestamp.valueOf();
  }

  return undefined;
}

interface DateTimePickerPropTypes {
  value           : number | undefined;
  disabled?: boolean;
  handleOnChange  : (date: string) => void;
  dataTestId?     : string
}

/**
 * @param value Unix timestamp (i.e. seconds from Unix epoch)
 * @param disabled
 * @param handleOnChange Handler for an ISO formatted date string
 * @param props
 */
export const DateTimePicker : FC<DateTimePickerPropTypes> = ({value, disabled, handleOnChange, ...props}) => {

  const inputHandleOnChange = (inputDateString: string) => {
    if(inputDateString.length === 0) {
      handleOnChange("");
    }else {
      const date = moment.utc(inputDateString, DateTimeLocalInputFormat, true);
      if (date.isValid())
        handleOnChange(date.toISOString());
    }
  }

  return (
    <InputGroup>
      {!disabled &&
          <InputGroup.Text className="cursor-pointer"
                           onClick={() => !disabled && handleOnChange("")}>X</InputGroup.Text>
      }
      <Form.Control
        {...props}
        type="datetime-local"
        disabled={disabled}
        value={value ? moment.utc(value).format(DateTimeLocalInputFormat) : ""}
        onChange={ e => inputHandleOnChange(e.target.value)}
      />
    </InputGroup>
  )
}
