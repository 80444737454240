import React from 'react';
import styles from './not-found.module.sass';

export const NotFound = () => {

  return (
    <div className={styles.notFound} data-testid="divNotFound">
      <i className="fas fa-user-times" />
      <h1>Not Found</h1>
      <p>Could not find what you are looking for.</p>
      <a className="btn btn-primary" href="https://portal.yakoffice.app">Click here to return to the <strong>portal</strong></a>
    </div>
  )
}
