import {useEffect, useState} from "react";
import {AxiosRequestConfig} from "axios";
import {buildHeaderOptions} from '../../utils';
import { useYakofficeUserIdToken } from '../use-yakoffice-user-idtoken';

export const useRequestHeader = () => {

  const token = useYakofficeUserIdToken();
  const [header, setHeader] = useState<AxiosRequestConfig | undefined>(undefined)

  // Only update this when token changes otherwise it would run everytime and cause infinite loop
  useEffect(() => {
    if (token)
      setHeader(buildHeaderOptions(token))
  }, [token])

  return header;
}
