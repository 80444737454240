import { UrlLinkProperty } from '../../../../model/data';
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";

export const UrlLinkPropertyComponent : FC<DataComponentPropTypes<UrlLinkProperty>> = ({route, dataObject, readonly, displayLabel}) => {

  const isValid = useCallback((value: string | undefined) => !(value == null || value.length === 0), []);

  return <PropertyComponent<string | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly}
        displayLabel={displayLabel}
        parseValue={v => v}
        isValid={isValid}
        formComponent={(value) => (
          <a href={value}
             className="btn btn-primary"
             target="_blank"
             rel="noopener noreferrer"
             style={{ zIndex: 0 }}
             data-testid={`hyperlink${dataObject.id}`}>
            <i className="fas fa-external-link-square-alt icon-with-text"/>
            <span className="text">  {dataObject.title}</span>
          </a>
        )}
    />
}
