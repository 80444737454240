import {TimestampProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import moment from "moment/moment";
import {DateTimePicker, parseDatetimeString} from '../Inputs/date-time-picker';
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {PostablePropertyComponent} from "./postable-property-component";

export const TimestampPostablePropertyComponent : FC<PostableComponentPropTypes<TimestampProperty>> = ({dataObject, ...props}) => {

    const isValid = useCallback((value: number | undefined) => {
        if(value == null && dataObject.isRequired)
            return false;

        return value != null && moment(value).isValid();
    }, [dataObject]);

    return <PostablePropertyComponent<number | undefined>
        {...props}
        parseValue={parseDatetimeString}
        labelDecorator={(label) => label + " (UTC)"}
        isValid={isValid}
        dataObject={dataObject}
        formComponent={(value, handleOnChange) => (
            <DateTimePicker value={value} handleOnChange={handleOnChange} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
