import {DoubleProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {NumberInput, parseDoubleString} from "../Inputs/number-input";
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {PostablePropertyComponent} from "./postable-property-component";

export const DoublePostablePropertyComponent : FC<PostableComponentPropTypes<DoubleProperty>> = ({dataObject, ...props}) => {

    const isValid = useCallback((value: number | undefined) => {
        return !(value == null && dataObject.isRequired);
    }, [dataObject]);

    return <PostablePropertyComponent<number | undefined>
        {...props}
        parseValue={parseDoubleString}
        isValid={isValid}
        dataObject={dataObject}
        formComponent={(value, handleOnChange) => (
            <NumberInput value={value} handleOnChange={handleOnChange} disabled={!dataObject.isEditable} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
