import { useParams } from 'react-router-dom';
import { useDataSources } from './use-data-sources';


export const useDataSource = () => {
  const { dataSourceId } = useParams<{ dataSourceId: string }>()
  const dataSources = useDataSources();

  return dataSources?.find(ds => ds.id === dataSourceId);
}
