import React, {FC} from "react";
import {NumberInput} from "./number-input";

interface PropTypes{
    value : number | undefined
    handleOnChange: (value: string) => void
    disabled?: boolean
    min?: number,
    max?: number,
}

export const IntInput : FC<PropTypes> = (props) => {

    return <>
        <NumberInput {...props}/>
        {(props.min != null || props.max != null) &&
        <small className="form-text text-muted">
            {props.min != null && `Min Value: ${props.min}`}
            {(props.min != null && props.max != null) && ", "}
            {props.max != null && `Max Value: ${props.max}`}
        </small>
        }
    </>
}
