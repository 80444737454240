import {useEffect, useState} from "react";
import {DataObjectBase} from "../../model/data";
import {IPropertyUpdate, useSavePropertyController} from "../use-save-property-controller";
import {useParams} from "react-router-dom";


export interface ISaveAllController{
  hasUnsavedUpdatedProperties(): boolean
  addUpdatedProperty(route: DataObjectBase[], value: any, formattedValue: any): void
  removeUpdatedProperty(route: DataObjectBase[]): void
  handleSaveAllUpdates() : Promise<void>
}

export const useSaveAllController = () : ISaveAllController => {

  const params = useParams()
  const [propertyUpdatesMap, setPropertyUpdatesMap] = useState<Map<string, IPropertyUpdate>>(new Map<string, IPropertyUpdate>())
  const savePropertyController = useSavePropertyController();

  useEffect(() => {
    setPropertyUpdatesMap(new Map<string, IPropertyUpdate>());
  }, [params])

  const mapRouteToId = (route: DataObjectBase[]) => route.map(o => o.id).join('_')

  const hasUnsavedUpdatedProperties = () => {
    return propertyUpdatesMap.size > 0
  }
  const addUpdatedProperty = (route: DataObjectBase[], value: any, formattedValue: any) =>{
    propertyUpdatesMap.set(mapRouteToId(route), {route,value,formattedValue});
    setPropertyUpdatesMap(new Map(propertyUpdatesMap))
  }
  const removeUpdatedProperty = (route: DataObjectBase[]) => {
    propertyUpdatesMap.delete(mapRouteToId(route))
    setPropertyUpdatesMap(new Map(propertyUpdatesMap))
  }
  const handleSaveAllUpdates = async () => {
    await savePropertyController.handleSaveProperties(Array.from(propertyUpdatesMap.values()));
    propertyUpdatesMap.clear()
  }

  return {
    hasUnsavedUpdatedProperties,
    addUpdatedProperty,
    removeUpdatedProperty,
    handleSaveAllUpdates
  };
}
