import Button from 'react-bootstrap/Button';
import React from 'react';
import {useShowModal} from '@yakoffice/custom-modal';
import styles from './delete-user-modal-styles.module.sass'
import {useNavigate} from 'react-router-dom';
import {CustomSpinner} from '@yakoffice/custom-spinner';


export interface IDeleteUserModal {
  startUserDeletionModal: (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => void;
  endUserDeletionModal: (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => void;
  resumeUserDeletionModal: (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => void;
}


export const useDeleteUserModal = (): IDeleteUserModal => {

  const showModal = useShowModal();
  const navigate = useNavigate()


  const closeModalAndRedirect = () => {
    showModal({show: false, title: "", body: "", canClose: false});
    navigate(0);
  }

  const startUserDeletionModal = async (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => {

    const doAction = async () => {

      showModal({show: true, title: `Delete User ${userId}`, body: <CustomSpinner spinnerText={"Starting player deletion. Please wait."} position={"relative"} />, dialogClassName: styles.dangerModal, canClose: false});

      const success = await modalAction();

      showModal({
                  show:            true,
                  title:           `Delete User ${userId}`,
                  body:            success ? <div className="white">Deletion process has been initiated.</div> : <div className="white">Failed to start deletion process.</div>,
                  action:          <Button variant="warning" onClick={() => closeModalAndRedirect()}>Close</Button>,
                  dialogClassName: styles.dangerModal,
                  canClose:        false
                });
    }

    showModal({
                show:            true,
                title:           `Delete User ${userId}`,
                body:            <div className="white">{modalBody}</div>,
                action:          <Button variant="danger" onClick={() => doAction()}>Permanently delete user</Button>,
                dialogClassName: styles.dangerModal,
                canClose:        true
              });
  }

  const resumeUserDeletionModal = async (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => {

    const doAction = async () => {

      showModal({show: true, title: `Resume User ${userId} Deletion`, body: <CustomSpinner spinnerText={"Resuming player deletion. Please wait."} position={"relative"} />, dialogClassName: styles.dangerModal, canClose: false});

      const success = await modalAction();

      showModal({
                  show:            true,
                  title:           `Resume User ${userId} Deletion`,
                  body:            success ? <div className="white">Deletion process has been initiated.</div> : <div className="white">Failed to resume deletion process.</div>,
                  action:          <Button variant="warning" onClick={() => closeModalAndRedirect()}>Close</Button>,
                  dialogClassName: styles.dangerModal,
                  canClose:        false
                });
    }

    showModal({
                show:            true,
                title:           `Resume User ${userId} Deletion`,
                body:            <div className="white">{modalBody}</div>,
                action:          <Button variant="danger" onClick={() => doAction()}>Resume user deletion</Button>,
                dialogClassName: styles.dangerModal,
                canClose:        true
              });
  }

  const endUserDeletionModal = async (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => {

    const doAction = async () => {

      showModal({show: true, title: `Delete User ${userId}`, body: <CustomSpinner spinnerText={"Starting player deletion. Please wait."} position={"relative"} />, dialogClassName: styles.dangerModal, canClose: false});

      const success = await modalAction();

      showModal({
                  show:            true,
                  title:           `Delete User ${userId}`,
                  body:            success ? <div className="white">The user has been successfully deleted.</div> : <div className="white">Failed to end the deletion user process.</div>,
                  action:          <Button variant="warning" onClick={() => closeModalAndRedirect()}>Close</Button>,
                  dialogClassName: styles.dangerModal,
                  canClose:        false
                });
    }

    showModal({
                show:            true,
                title:           `Delete User ${userId}`,
                body:            <div className="white">{modalBody}</div>,
                action:          <Button variant="danger" onClick={() => doAction()}>Confirm user is deleted</Button>,
                dialogClassName: styles.dangerModal,
                canClose:        true
              });
  }

  return {
    startUserDeletionModal,
    endUserDeletionModal,
    resumeUserDeletionModal
  };
};
