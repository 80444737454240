import {BooleanProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {BooleanInput, parseBooleanString} from "../Inputs/boolean-input";
import {PostableComponentPropTypes} from "./postable-component-prop-types";
import {PostablePropertyComponent} from "./postable-property-component";

export const BooleanPostablePropertyComponent : FC<PostableComponentPropTypes<BooleanProperty>> = ({dataObject, ...props}) => {

    const isValid = useCallback((value: boolean | undefined) => {return !(value == null && dataObject.isRequired);}, [dataObject]);

    return <PostablePropertyComponent<boolean | undefined>
        {...props}
        parseValue={parseBooleanString}
        isValid={isValid}
        dataObject={dataObject}
        formComponent={(value, handleOnChange) => (
            <BooleanInput value={value} handleOnChange={handleOnChange} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
