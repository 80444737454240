import { ImageProperty } from '../../../../model/data';
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import { ImageInput } from '../Inputs/image-input';

export const ImagePropertyComponent : FC<DataComponentPropTypes<ImageProperty>> = ({route, dataObject, readonly, displayLabel}) => {

  const isValid = useCallback((value : string | undefined) => !(value == null || value.length === 0), []);

  return <PropertyComponent<string | undefined>
    route={route}
    dataObject={dataObject}
    readonly={readonly}
    displayLabel={displayLabel}
    parseValue={v => v}
    isValid={isValid}
    formComponent={(value, handleOnChange, disabled) => (
      <ImageInput value={value} handleOnChange={handleOnChange} disabled={disabled} maxLength={dataObject.maxLength} data-testid={`input${dataObject.id}`}/>
    )}
  />
}
