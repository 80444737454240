import React, {FC} from "react";
import {Form} from "react-bootstrap";

interface PropTypes{
    value : boolean | undefined
    handleOnChange: (value: string) => void
    disabled?: boolean
}

export const BooleanInput : FC<PropTypes> = ({value, handleOnChange, disabled, ...props}) => {

    return <Form.Select
                         value={value != null ? value.toString() : ""}
                         onChange={e => handleOnChange(e.target.value)}
                         disabled={disabled}
                         {...props}>
        <option value="">Select a value...</option>
        <option>true</option>
        <option>false</option>
    </Form.Select>
}

export const parseBooleanString = (value: string) => {
    return value.length > 0 ?  value.toLowerCase() === "true" : undefined;
}
