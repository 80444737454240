

export const buildHeaderOptions = (bearerToken: string) => {
    return {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + bearerToken
        }
    }
}